import styled from '@emotion/styled';
import { FOOTER_HEIGHT } from '../constants';

const AppContainer = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 100vh;
  padding-bottom: ${FOOTER_HEIGHT};

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;

    background-image: url(${require('../static-data/CrossLogo512.png')});
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: 90%;
    @media screen and (min-width: 800px) {
      background-size: 800px;
    }
  }
`;

export default AppContainer;
